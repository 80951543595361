import React from 'react'
import { Div } from 'ui/modules/styled-jsx/widgets'
import { Layout } from 'ui'
import { Image, P } from 'fractals'
import { TAuthorBadgeProps } from './types'
import * as stls from './styles'
import { VARIANT_KEYS } from './constants'

export const AuthorBadge = ({
  styles,
  position,
  author,
  variant = 'default'
}: TAuthorBadgeProps) => {
  const variants = {
    [VARIANT_KEYS.default]: {
      job: 'xiBadge',
      name: 'omicronBadge'
    } as const,
    [VARIANT_KEYS.homeVideo]: {
      job: 'jobVideo',
      name: 'nameVideo'
    } as const,
    [VARIANT_KEYS.programVideo]: {
      job: 'jobVideoPrograms',
      name: 'nameVideoPrograms'
    } as const
  }

  return (
    <Layout.FlexItem
      styles={{
        ...styles,
        ...stls.variants[variant].authorBadge(position).container
      }}>
      <Div styles={stls.variants[variant].imageContainer}>
        <Image
          colorVariant='transparent'
          fill
          containerProps={{
            styles: {
              borderRadius: '50%',
              '& img': {
                objectFit: 'cover'
              }
            }
          }}
          src={author?.image?.url || '/'}
          alt=''
        />
      </Div>
      {author !== undefined && (
        <Div
          styles={stls.variants[variant].author}
          itemProp='author'
          itemScope
          itemType='https://schema.org/Person'>
          {author?.job && <P variant={variants[variant].job}>{author?.job}</P>}
          {author?.name && (
            <P variant={variants[variant].name} itemProp='name'>
              {author?.name}
            </P>
          )}
        </Div>
      )}
    </Layout.FlexItem>
  )
}

export default AuthorBadge
