/* eslint-disable import/no-cycle */
import React from 'react'
import dynamic from 'next/dynamic'
import { Main, useTranslations, TRANSLATIONS, PATHS } from 'fractals'
import { Shared } from 'modules'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { Hero } from './widgets'
import { THomePageProps, H1, IDS, SEO_TITLE, SEO_DESCRIPTION } from './fractals'
import { Spinner } from '@/fractals/components'

const DynamicFaculties = dynamic(
  () => import('./widgets/Faculties/Faculties'),
  { loading: () => <Spinner /> }
)
const DynamicMission = dynamic(() => import('./widgets/Mission/Mission'), {
  loading: () => <Spinner />
})
const DynamicTestimonials = dynamic(
  () => import('./widgets/Testimonials/Testimonials'),
  { loading: () => <Spinner /> }
)
const DynamicStudySteps = dynamic(
  () => import('modules/shared/widgets/StudySteps/StudySteps'),
  { loading: () => <Spinner /> }
)
const DynamicWhyUs = dynamic(
  () => import('modules/shared/widgets/WhyUs/WhyUs'),
  { loading: () => <Spinner /> }
)
const DynamicDiplomas = dynamic(
  () => import('modules/shared/widgets/Diplomas/Diplomas'),
  { loading: () => <Spinner /> }
)
const DynamicVideo = dynamic(
  () => import('modules/shared/widgets/Video/Video'),
  { loading: () => <Spinner /> }
)
const DynamicJournal = dynamic(() => import('./widgets/Journal/Journal'), {
  loading: () => <Spinner />
})
const DynamicFAQ = dynamic(() => import('modules/shared/widgets/FAQ/FAQ'), {
  loading: () => <Spinner />
})
const DynamicFooter = dynamic(
  () => import('modules/shared/widgets/Footer/Footer'),
  { loading: () => <Spinner /> }
)

const HomePage = ({
  stories,
  faculties,
  mission,
  diplomas,
  whyUs,
  questions,
  categories,
  videos,
  journals,
  allJournals,
  facultiesFromJournals,
  tags,
  images,
  footer
}: THomePageProps) => {
  const [companyName, seoTitle, seoDescription] = useTranslations(
    TRANSLATIONS.companyName,
    SEO_TITLE,
    SEO_DESCRIPTION
  )

  const seoParams = {
    title: seoTitle,
    desc: seoDescription,
    canonical: `${PATHS.front.home.index}`
  }

  return (
    <>
      <Head>
        <title itemProp='headline'>{seoParams.title}</title>
        <meta
          itemProp='description'
          name='description'
          content={seoParams.desc}
        />
      </Head>
      <NextSeo
        canonical={seoParams.canonical}
        openGraph={{
          url: seoParams.canonical,
          title: seoParams.title,
          description: seoParams.desc,
          images: [
            {
              url: `${PATHS.front.root.index}${PATHS.front.public.assets.imgs.icons.mitmLogo512.index}`,
              width: 512,
              height: 512,
              alt: companyName,
              type: 'image/png'
            }
          ],
          site_name: companyName
        }}
      />
      <Shared.PageContainer id={IDS.homePage}>
        <Shared.Header images={images.value} categories={categories.value} />
        <Main>
          <Hero stories={stories.value} h1={H1} />
          <DynamicFaculties faculties={faculties?.value} />
          <DynamicMission mission={mission?.value} />
          <DynamicTestimonials />
          <DynamicStudySteps />
          <DynamicWhyUs whyUs={whyUs?.value} />
          <DynamicDiplomas diplomas={diplomas?.value} />
          <DynamicVideo id={IDS.video} videos={videos?.value} />
          <DynamicJournal
            id={IDS.journal}
            journals={journals?.value}
            allJournals={allJournals?.value}
            tags={tags?.value}
            facultiesFromJournals={facultiesFromJournals?.value}
          />
          <DynamicFAQ questions={questions?.value} />
          <Shared.Scroll />
          {/* <Shared.AskModal /> */}
        </Main>
        <DynamicFooter footer={footer?.value} />
      </Shared.PageContainer>
    </>
  )
}

export default HomePage
