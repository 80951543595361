import { LOCALES } from './locales'

const COMPANY_NAME = {
  [LOCALES.en]: 'Moscow Institute of Technology and Management',
  [LOCALES.ru]: 'Московский Институт Технологий и Управления',
  [LOCALES.uz]: 'Moskovskiy Instituti Texnologiy i Upravlenie',
  [LOCALES.kk]: 'Московский Институт Технология және Управления'
} as const

export const ADDRESS = {
  [LOCALES.en]: 'Tashkent city, Afrosiyob street, 8a, Dmaar',
  [LOCALES.ru]: 'г. Москва, Дербеневская набережная, д. 11',
  [LOCALES.uz]: "Toshkent shahri, Afrosiyob ko'chasi, 8a, Dmaar",
  [LOCALES.kk]:
    'Алматы қаласы, Аль-Фараби проспекті, 17, Нұрлы-Тау Бизнес Центрі, Блок 4Б, офис 1603'
} as const

export const ADDRESS_MOSCOW = {
  [LOCALES.en]: 'Derbenevskaya naberezhnaya, 11',
  [LOCALES.ru]: 'Дербеневская набережная, д. 11',
  [LOCALES.uz]: 'Derbenevskaya naberezhnaya, 11',
  [LOCALES.kk]: 'Дербеневская набережная, 11'
} as const

export const ADDRESS_TASHKENT = {
  [LOCALES.en]: "Afrosiyob ko'chasi, 8a, Dmaar",
  [LOCALES.ru]: 'ул. Афросиаб, 8а, БЦ Dmaar',
  [LOCALES.uz]: "Afrosiyob ko'chasi, 8a, Dmaar",
  [LOCALES.kk]: 'Афросиёб көчесі, 8а, Дмаар'
} as const

export const ADDRESS_ALMATY = {
  [LOCALES.en]:
    'Residential Complex "House on Abay", Gagarin Avenue, 124, 4th floor, Bostandyk District, Almaty, 050057',
  [LOCALES.ru]: 'г. Алматы, Проспект Абая, 44А, PROMENADE, Business Park',
  [LOCALES.uz]:
    'Residential Complex "House on Abay", Gagarin Avenue, 124, 4th floor, Bostandyk District, Almaty, 050057',
  [LOCALES.kk]:
    '“Абай үйі” ТК, Гагарин даңғылы, 124, 4-қабат, Бостандық ауданы, Алматы, 050057'
} as const

export const PHONE_NUMBER_ALPHA = {
  [LOCALES.en]: '+998 (78) 122-62-26',
  [LOCALES.ru]: '+7(800)333-71-38',
  [LOCALES.uz]: '+998 (78) 122-62-26',
  [LOCALES.kk]: '+7 (727) 311-09-11'
} as const

export const PHONE_NUMBER_BETA = {
  [LOCALES.en]: '',
  [LOCALES.ru]: '+7(495)648-62-23',
  [LOCALES.uz]: '',
  [LOCALES.kk]: '+7 (771) 766-22-20'
} as const

export const PHONE_NUMBER_MOSCOW_ALPHA = {
  [LOCALES.en]: '+7(800)333-71-38',
  [LOCALES.ru]: '+7(800)333-71-38',
  [LOCALES.uz]: '+7(800)333-71-38',
  [LOCALES.kk]: '+7(800)333-71-38'
} as const

export const PHONE_NUMBER_MOSCOW_BETA = {
  [LOCALES.en]: '+7(495)648-62-23',
  [LOCALES.ru]: '+7(495)648-62-23',
  [LOCALES.uz]: '+7(495)648-62-23',
  [LOCALES.kk]: '+7(495)648-62-23'
} as const

export const PHONE_NUMBER_TASHKENT_ALPHA = {
  [LOCALES.en]: '+998 (78) 122-62-26',
  [LOCALES.ru]: '+998 (78) 122-62-26',
  [LOCALES.uz]: '+998 (78) 122-62-26',
  [LOCALES.kk]: '+998 (78) 122-62-26'
} as const

export const PHONE_NUMBER_ALMATY_ALPHA = {
  // [LOCALES.en]: '+7 (727) 311-09-11',
  [LOCALES.en]: '+7 (771) 766-22-24',
  [LOCALES.ru]: '+7 (771) 766-22-24',
  [LOCALES.uz]: '+7 (771) 766-22-24',
  [LOCALES.kk]: '+7 (771) 766-22-24'
  // [LOCALES.ru]: '+7 (727) 311-09-11',
  // [LOCALES.uz]: '+7 (727) 311-09-11',
  // [LOCALES.kk]: '+7 (727) 311-09-11'
} as const

export const PHONE_NUMBER_ALMATY_BETA = {
  [LOCALES.en]: '+7 (771) 766-22-23',
  [LOCALES.ru]: '+7 (771) 766-22-23',
  [LOCALES.uz]: '+7 (771) 766-22-23',
  [LOCALES.kk]: '+7 (771) 766-22-23'
  // [LOCALES.en]: '+7 (771) 766-22-20',
  // [LOCALES.ru]: '+7 (771) 766-22-20',
  // [LOCALES.uz]: '+7 (771) 766-22-20',
  // [LOCALES.kk]: '+7 (771) 766-22-20'
} as const

export const PHONE_NUMBER_ALMATY_GAMMA = {
  [LOCALES.en]: '',
  [LOCALES.ru]: '',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const FREE_CALL_RU = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Бесплатно по России',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const FREE_CALL_MSC = {
  [LOCALES.en]: '',
  [LOCALES.ru]: 'Бесплатно по Москве',
  [LOCALES.uz]: '',
  [LOCALES.kk]: ''
} as const

export const STATE_LICENCE = {
  [LOCALES.ru]: 'Государственная лицензия',
  [LOCALES.en]: 'State Licence',
  [LOCALES.uz]: 'Davlat litsenziyasi',
  [LOCALES.kk]: 'Дербес дипломы'
} as const

export const STATE_LICENCE_NUMBER = {
  [LOCALES.ru]: '№1207700217977',
  [LOCALES.en]: '№1207700217977',
  [LOCALES.uz]: '№1207700217977',
  [LOCALES.kk]: '№1207700217977'
} as const

export const EMAIL = {
  [LOCALES.ru]: 'info@mitm.institute',
  [LOCALES.en]: 'info@mitm.institute',
  [LOCALES.uz]: 'info@mitm.institute',
  [LOCALES.kk]: 'info@mitm.institute'
} as const

export const EMAIL_ALMTY = {
  [LOCALES.en]: 'almaty@mitm.institute',
  [LOCALES.ru]: 'almaty@mitm.institute',
  [LOCALES.uz]: 'almaty@mitm.institute',
  [LOCALES.kk]: 'almaty@mitm.institute'
} as const

export const EMAIL_TASHKENT = {
  [LOCALES.en]: 'info@mitm.institute',
  [LOCALES.ru]: 'info@mitm.institute',
  [LOCALES.uz]: 'info@mitm.institute',
  [LOCALES.kk]: 'info@mitm.institute'
} as const

export const TRANSLATIONS = {
  companyName: COMPANY_NAME,
  address: ADDRESS,
  addressMoscow: ADDRESS_MOSCOW,
  addressTashkent: ADDRESS_TASHKENT,
  addressAlmaty: ADDRESS_ALMATY,
  phoneNumberAlpha: PHONE_NUMBER_ALPHA,
  phoneNumberBeta: PHONE_NUMBER_BETA,
  phoneNumberMoscowAlpha: PHONE_NUMBER_MOSCOW_ALPHA,
  phoneNumberMoscowBeta: PHONE_NUMBER_MOSCOW_BETA,
  phoneNumberTashkentAlpha: PHONE_NUMBER_TASHKENT_ALPHA,
  phoneNumberAlmatyAlpha: PHONE_NUMBER_ALMATY_ALPHA,
  phoneNumberAlmatyBeta: PHONE_NUMBER_ALMATY_BETA,
  phoneNumberAlmatyGamma: PHONE_NUMBER_ALMATY_GAMMA,
  freeCallRu: FREE_CALL_RU,
  freeCallMsc: FREE_CALL_MSC,
  stateLicence: STATE_LICENCE,
  stateLicenceNumber: STATE_LICENCE_NUMBER,
  email: EMAIL,
  emailAlmaty: EMAIL_ALMTY,
  emailTashkent: EMAIL_TASHKENT
} as const
