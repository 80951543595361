import {
  Container,
  H2,
  multiMedia,
  Section,
  useMedia,
  useTranslations
} from 'fractals'
import { slugify } from 'ui'
// eslint-disable-next-line import/no-cycle
import {
  HEADING,
  TProgramsProps,
  CategoryButtons,
  ProgramCards
} from './fractals'

export const Programs = ({
  categories,
  faculties,
  programs,
  params
}: TProgramsProps) => {
  const [heading] = useTranslations(HEADING)

  const { isDesktop, isMobile } = useMedia()

  return (
    <Section
      spacingVariant='beta'
      id={slugify(heading)}
      styles={{
        paddingTop: 0
      }}>
      <Container
        styles={{
          ...multiMedia({
            laptop: {
              maxWidth: '800px'
            },
            tablet: {
              maxWidth: '640px'
            }
          })
        }}>
        {!isDesktop && <H2 variant='delta'>{heading}</H2>}
        {!isMobile && <CategoryButtons categories={categories} />}
      </Container>
      <Container>
        <ProgramCards
          params={params}
          faculties={faculties}
          programs={programs}
          categories={categories}
        />
      </Container>
    </Section>
  )
}

export default Programs
