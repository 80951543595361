import {
  Section,
  Container,
  P,
  toUnit,
  Image,
  H2,
  useTranslations,
  Div,
  useMedia
} from 'fractals'
import { Layout, slugify } from 'ui'
import { useTheme } from 'styled-components'
import Color from 'color'
import { Line, TITLE, TReviewsProps } from './fractals'
import defaultAvatar from './fractals/images/default-avatar.jpg'

// TODO: refactor this to make sure the fallbacks are in place
const Reviews = ({ colorVariant, reviews }: TReviewsProps) => {
  const [title] = useTranslations(TITLE)
  const { isDesktop, isTablet, isMobile } = useMedia()
  const { colors } = useTheme()

  if (!reviews || reviews?.length === 0) return null

  return (
    <Section
      id={slugify(title)}
      spacingVariant='delta'
      colorsVariant='delta'
      styles={{
        ...(isMobile && { paddingTop: toUnit(56) })
      }}>
      <Container>
        <H2
          variant='beta'
          styles={{
            textAlign: isMobile ? 'start' : 'center',
            width: '100%',
            ...(isMobile && { marginBottom: toUnit(40) })
          }}>
          {title}
        </H2>
        {reviews.map((review, idx) => (
          <Div
            key={`Reviews__renderReviews__renderReview--${idx + 1}`}
            styles={{ marginBottom: toUnit(isMobile ? 16 : 24) }}>
            <Line colorVariant={colorVariant} />
            <Layout.Grid
              styles={{
                grid: 'auto / 0fr 1fr',
                columnGap: toUnit(16),
                rowGap: toUnit(8),
                alignItems: 'center',
                marginBottom: toUnit(isDesktop ? 32 : 16)
              }}>
              <Image
                colorVariant='transparent'
                src={review?.image?.url || defaultAvatar}
                alt={review?.image?.alternativeText || ''}
                borderRadiusVariant='circle'
                fill
                containerProps={{
                  styles: {
                    position: 'relative',
                    gridRow: 'span 2',
                    width: toUnit(isDesktop ? 48 : 40),
                    '& img': { objectFit: 'cover' }
                  }
                }}
              />
              {review?.name && <P variant='epsilon'>{review.name}</P>}
              {review?.position && (
                <P
                  variant='beta'
                  styles={{
                    fontSize: toUnit(12),
                    textTransform: 'uppercase',
                    color:
                      (colorVariant?.color &&
                        Color(colorVariant?.color).rgb().string()) ||
                      colors.beta.rgb().string()
                  }}>
                  {review.position}
                </P>
              )}
            </Layout.Grid>
            {review?.body && (
              <P
                variant='delta'
                styles={{
                  ...((isTablet || isMobile) && { fontSize: toUnit(14) })
                }}>
                {review.body}
              </P>
            )}
          </Div>
        ))}
        <Line last colorVariant={colorVariant} />
      </Container>
    </Section>
  )
}

export default Reviews
