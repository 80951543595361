import * as React from 'react'
import { StyledJSX } from 'ui'
import { Container, toUnit, media } from 'fractals'
import { TFooterProps } from './types'
// import { VARIANTS_KEYS } from './constants'

const Footer = ({
  /* variant = VARIANTS_KEYS.alpha, */ children,
  ...props
}: TFooterProps) => {
  // const stylesVariants = {
  //   [VARIANTS_KEYS.alpha]: {}
  // } as const

  const styles = {
    // DEFAULT STYLES WOULD GO HERE
    ...media('paddingTop', toUnit(60), {
      laptop: toUnit(55),
      desktop: toUnit(88)
    }),
    ...media('paddingBottom', toUnit(16), {
      laptop: toUnit(22),
      desktop: toUnit(24)
    }),
    backgroundColor: 'var(--omega)',
    color: 'var(--alpha)',
    // ...stylesVariants[variant],
    ...props.styles
  } as const

  return (
    <StyledJSX.Footer {...props} styles={styles}>
      <meta itemProp='copyrightYear' content='2025' />
      <meta
        itemProp='copyrightHolder'
        content='Московский Институт Технологий и Управления'
      />
      <Container itemScope itemType='http://schema.org/Organization'>
        {children}
      </Container>
    </StyledJSX.Footer>
  )
}

export default Footer
